
@media (max-width: 768px) {
    .w-9\/10 {
        width: 90%;
    }
}
.contact-information-popover{
    display: none;
    border-radius: 8px;
}
.contact-information-popover::before{
    display: block;
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid white;
    right: 100%;
    bottom: 5%;
    box-shadow: 20px 20px #0000;
    z-index: 99999999;

}
.priceInfo-hover{
    display: none;
    border-radius: 8px;
}
.priceInfo-hover::before{
    display: block;
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 14px solid white;
    right: 100%;
    bottom: 5%;
    box-shadow: 20px 20px #0000;
    z-index: 99999999;
}
.trailingicon:hover ~ .contact-information-popover{
    display: block;
}
.priceInfo:hover ~ .priceInfo-hover{
    display: block;
}

.traiangle-left {
    height: 0px;
    width: 0px;
    position: relative;
    border-right: solid 14px #C4C4C4;
    border-bottom: solid 14px transparent;
    border-top: solid 14px transparent;
  }
  
  .traiangle-right {
    height: 0px;
    width: 0px;
    position: relative;
    border-top: solid 14px transparent;
    border-left: solid 14px #C4C4C4;
    border-bottom: solid 14px transparent;
  }
