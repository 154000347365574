@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

:root {
  --brand-dark-blue: rgba(88, 189, 196, 1);
  --primary-opaque: rgba(88, 189, 196, 1);
  --primary: rgba(88, 189, 196, 0.9);
  --premium: #5858df;
  --premium-translucent: rgba(88, 88, 223, 0.7);
  --brand-primary: rgba(88, 189, 196, 0.9);
  --brand-secondary: #2c2733;
  --text: #333;
  --text-muted: #9a9ab0;
  --text-blue: rgba(55, 162, 186, 1);
  --link-color: var(--text-blue);
  --link-color-light: #1ac0c6;
  --btn-primary: rgba(2, 2, 81, 1);
  --primary-font: #6e7785;
}

html,
body {
  font-family: 'DM Sans';
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

.content-page {
  color: #111;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  min-height: calc(100vh - 160px);
  font-size: 20px;
}
.align-items-end {
  align-items: flex-end;
}
.content-page a:not([href]) {
  color: #111;
  text-decoration: none !important;
}

.content-page li {
  margin-left: 50px;
}
.content-page h1 {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 50px;
  font-weight: bold;
}
.content-page p {
  margin-top: 0px;
  margin-bottom: 5px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
.content-page ol {
  list-style-type: auto !important;
}
.content-page a {
  color: #1155cc;
  text-decoration: underline;
}

.react-select__input:focus {
  box-shadow: none;
}

.react-select__input-container {
  box-shadow: none;
}

.react-select__control {
  margin: 0 !important;
  padding: 0 !important;
  min-height: fit-content !important;
}

.react-select__multi-value {
  min-width: auto !important;
}
.react-select__value-container--is-multi {
  padding-left: 0 !important;
}
.react-select__option--is-focused {
  border-radius: 1.5rem;
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #65c2d7 !important;
  display: inline-block;
}
.react-select__multi-value__label {
  color: #65c2d7 !important;
}
.react-select__multi-value path {
  fill: #65c2d7;
}
.react-select__multi-value {
  border-radius: 1.5rem !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.bold-text {
  font-weight: bold;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: bold;
}

.alert-danger {
  color: #d8315b;
  border-color: #d8315b;
}

.alert-success {
  color: #5dd870;
  border-color: #5dd870;
}

.input-container {
  position: relative;
  height: 40px;
}
.input-container input {
  width: 100%;
  height: 100%;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.text-muted {
  /* color: #9a9ab0!important; */
}

.flex-space-between {
  justify-content: space-between !important;
}

.container {
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#profile-list {
  overflow-y: auto;
}

.focus-within-outline:focus-within {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.no-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
