.review-root-container {
    color: #000;
    display: flex;
    flex-direction: column;
}

.review-root-container .review-box {  
    padding-bottom: 12px;
    padding-right: 8px;
}

.review-box .stick {
    margin-top:8px;
    margin-bottom: -20px;
    margin-right: 10px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.review-box .response-container {
    background: rgba(141, 224, 213, 0.2);
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
}

.back-container {
    color: #264B85;
    padding-left: 6.35vw;
}