.slider--container {
    width: 24.41vw;
    min-width: 300px;
    position: relative;
    height: 60px;
}
.slider {
    position: absolute;
    width: 100%;
    height: 100%;
}
.slider.disabled {
    opacity: 0.5;
}
.example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    bottom:8px;
    background: #ffffff;
    border: 1px solid #20BFE7;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: block;
}
.example-thumb.active {
    background: #E5E7EB;
    outline: none;
}
.example-thumb svg{
    position: absolute;
    top: -22px;
    left: -50px;
}
.slider .example-track {
    bottom: 20px;
    position: relative;
    background: #20BFE7;
    height: 4px;
    border-radius: 4px;
}
.example-track.example-track-2, .example-track.example-track-0 {
    background: rgba(0,0,0,.5);
}
.horizontal-slider .example-thumb {
    top: 12px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
}
.slider-minmax {
 position: absolute;
 top: 0;
 color: rgba(0,0,0,.5);
}
.slider-minmax--max {
    right:0;
}
.thumb-value {
    font-size: 14px;
    font-weight: bold;
    color: black;
    position: absolute;
    top: -12px;
    left: -48px;
}
.hide-value .thumb-value, .hide-value svg {
    display: none;
}
 .slider-title {
    position: absolute;
    right: 10px;
     bottom: -8px;
     font-weight: 500;
     color: black;
}
