.react-calendar-container{
    width:430px;
    height: 430px;
    padding: 20px;
}
.react-calendar{
    
    border: none;
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    box-shadow: #8C959A 1px;
}
.react-calendar__month-view__weekdays > .react-calendar__month-view__weekdays__weekday{
    font-weight: normal;
    font-size: 14px;
    font-family: sans-serif;
    color: #8C959A;
    text-decoration: none;
    text-transform: capitalize;
}
abbr:where([title]) {
    text-transform: none;
}
.react-calendar__month-view__days__day--weekend{
    color: #000000;
    font-size: 14px;
    line-height: 18px;
}
.calander-details{
    font-size: 16px;
    line-height: 20px;
    color: #000;
}
.react-calendar__tile--now{
    background: none;
}
.react-calendar__tile{
    height: 50px;
    width: 50px;
}
.react-calendar__tile:hover{
    height: 50px;
    width: 50px;
    background: #8C959A;
}
.react-calendar__tile--active{
    background: #49CEEB;
    border-radius: 50% !important;
}